.content_box_resumen_list li {
    font-size: .9em;
    color: var(--color-secondary);
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin-left: 20px;
    padding: 0;
    margin: 0;
}
.content_box_resumen_list li:hover{
    background-color: #f5f5f5;
}
.content_box_resumen_list li:hover .content_box_resumen_icon{
opacity:.4 ;
}  
.content_box_resumen_list_item {
    border-left: 5px solid var(--color-primary);
    border-bottom: 1px solid #d5d5d55d;
    display: flex;
    align-items: center;
}
.content_box_resumen_icon_div{
    width:  5px;
    margin-left: auto;
    padding-right: 10px;
}
.content_box_resumen_icon{
    width: 70px;
    margin-left:-70px;
    opacity: .2;
}
.content_box_resumen_list_item ul {
    padding: 10px;
}
.content_box_resumen_list_item ul li:nth-child(1):before {
    content: "iiiii";
    color: transparent;
    width: 15px;
    height: 15px;
    margin-left: -21px;
    background-color: #d5d5d5;
    border-radius: 50%;
    margin-right: 2px;
    border: 1px solid var(--color-primary);
}
.active li:nth-child(1):before {
    background-color: var(--color-primary) !important;
}
.content_box_resumen_list_item ul li {
    padding-bottom: 4px;
    
}
.content_box_resumen_list_item ul li {
    font-size: .8rem;
    color: var(--color-terceary);
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
    line-height: .8rem;
    color: #161616;
    margin-top: 3px;
   
}
.content_box_resumen_list_item ul li span{
    font-weight: bold;
    color: var(--color-secondary);
}

.content_box_resumen{
    display:flex;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 7px;
}
.column{
    width: 50%;
}
@media (max-width: 660px) {
    .content_box_resumen{
        display:block;
    }
    .column{
        width: 100%;
    }
}