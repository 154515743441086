.react-responsive-modal-modal {
  background: var(--color-primary);
  border-radius: 10px;
  padding: 20px;
  margin: 0 !important;
  width: 95%;
  max-width: 1200px !important;
  height: 80%;
  max-height: 800px;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  animation: modal 0.3s ease-in-out;
}
.title_modal {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  color: var(--color-secondary);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
}
.sub_title_modal {
  font-family: "Montserrat", sans-serif;
  font-size: 0.7em;
  font-weight: 400;
  color: var(--color-secondary);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}
.text_modal {
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem !important;
  font-weight: 400;
  color: var(--color-secondary);
  text-align: justify;
  margin-bottom: 20px;
}
.content_modal {
  display: flex;
  flex-direction: row;
}
.poster_modal {
  width: 60%;
  height: 100%;
  object-fit: contain;
}
.text_modal {
  width: 40%;
  padding: 10px;
  font-size: 0.7em;
  line-height: 1.4em;
  font-weight: 400;
  color: var(--color-secondary);
  text-align: justify;
  margin-bottom: 20px;
  white-space: initial;
}
.btn_modal {
  min-width: 100px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
  float: right;
  position: relative;
  margin-top: 50px;
  outline: none;
  border-radius: 5px;
  z-index: 0;
  background: #fff;
  overflow: hidden;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
}
.btn_modal:hover {
  color: #fff;
}
.btn_modal:hover:after {
  height: 100%;
}
.btn_modal:after {
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  background: #57cc99;
}
@media (max-width: 660px) {
  .content_modal {
    display: flex;
    flex-direction: column;
  }
  .poster_modal {
    width: 100%;
  }
  .text_modal {
    width: 99%;
    padding: 5px;
    box-sizing: border-box;
  }
  .title_modal {
    font-size: 0.8em;
    width: 80%;
    margin-left: 10%;
  }
  .sub_title_modal {
    font-size: 0.6em;
    width: 80%;
    margin-left: 10%;
  }
}
