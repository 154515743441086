.Skill {
    
    font-family: 'Montserrat', sans-serif;
    
   
}
.Skill_List{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* dos columnas */
}
@media (max-width: 660px) {
    .Skill_List{
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr; /* dos columnas */
    }
}
@media only screen and (min-width:660px) and (max-width: 1000px) {
    .Skill_List{
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr ; /* dos columnas */
    }
}


.Skill_List li {
    margin-right: 10px;
    margin-bottom: 20px;
}

.Skill_Label {
    font-size: 1.1em;
    line-height: 1em;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 2px;
    margin-left: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    gap: 14px 8px;
    
}
.Skill_Label i {
    color: rgb(38, 57, 77);
    /* shadow */
    text-shadow: 1px 1px rgba(38, 57, 77, 0.151);
    font-size: 1.5rem;


}

