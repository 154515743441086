.grid_portafolio {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}
@media (max-width: 660px) {
  .grid_portafolio {
    grid-template-columns: repeat(2, 1fr);
  }
}
.item_portafolio {
  position: relative;
  cursor: pointer;
  border: 1px solid #d5d5d5;
  transition: all 2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}
.item_image_portafolio {
  width: 80%;
  height: 80%;
  margin-top: 10%;
  margin-left: 10%;
  object-fit: contain;
}
.item_portafolio:hover {
  transform: scale(1.1);
}
.item_portafolio:hover .item_cortina {
  transform: scaleY(1);
  opacity: 1;
}
.item_cortina {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  border: 1px solid #ffffff;
  transform: scaleY(0);
  opacity: 0;
  transform-origin: 0 100%;
  transition: all 1s ease-in-out;
  border: 1px solid var(--color-primary);
  background: #1ba050ed;
}

.item_cortina_text {
  position: absolute;
  padding: 3px;
  text-align: center;
  font-family: "Roboto";
  font-size: 2em;
  font-weight: bold;
  height: 55%;
  width: 100%;
  font-weight: 400;
  text-transform: uppercase;
  bottom: 0;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
