#buscador {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content_box_title {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.Main_Icons {
  margin-right: 10px;
  color: #555;
}

.content_box_buscador {
  display: flex;
  width: 100%;
  max-width: 100%;
}

.content_box_buscador_input {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  outline: none;
}

.content_box_buscador_input:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 4px var(--color-primary);
}

.content_box_buscador_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 2px 5px 15px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-primary);
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.content_box_buscador_button:hover {
  background-color: var(--color-primary);
}

.content_box_buscador_button .Main_Icons {
  margin-left: 2px;
  color: #fff;
}
.content_box_results {
  width: 100%;
}
.Card {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%; /* Ensure the card takes full width of the container */

  text-decoration: none;
  color: inherit;
}

.Card > *:nth-child(1) {
  flex: 1 1 25%;
  box-sizing: border-box; /* Ensure proper box model */
}

.Card > *:nth-child(2) {
  flex: 1 1 75%;
  box-sizing: border-box; /* Ensure proper box model */
}

.Card > *:nth-child(3) {
  flex-basis: 100%;
  box-sizing: border-box; /* Ensure proper box model */
}

@media (max-width: 768px) {
  .Card > *:nth-child(1),
  .Card > *:nth-child(2) {
    flex: 1 1 100%;
    box-sizing: border-box; /* Ensure proper box model */
  }
}

.Card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Card-Image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 10px;
  height: auto;
  display: block;
}

.Card-text {
  padding: 15px;
}

.Card-text h3 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.Card-text p {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
}

.Card-footer {
  padding: 10px 15px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
  font-size: 0.9rem;
  color: #777;
  text-align: right;
}
.no-results-message {
  background-color: rgba(188, 143, 143, 0.336);
  color: #000;
  padding: 20px;
  margin-top: 10px;
  text-align: center;
  border-radius: 20px;
  width: 80%;
  margin-left: 8%;
}
.load-more-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-primary);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.load-more-button:hover {
  background-color: #11c05469;
  transform: translateY(-2px);
}

.load-more-button:active {
  transform: translateY(0);
}

.load-more-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
