
:root{
    --progres-scroll-y: 0;
}
.Main {
    width: 25vw;
    height: 100vh;
    position: fixed;
    background-color: var(--color-secondary);
}

.Main_Avatar {
    width: 40%;
    margin: 10% 30% 10% 30%;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    border: 3px solid var(--color-primary);
    border-left: 1px;
    border-top: 0;
    position: relative;
    box-shadow: rgb(38, 57, 77) 0px 10px 15px -10px;
    filter: drop-shadow(0 2px 100px rgba(255, 255, 255, 0.4));
    cursor: pointer;
    transition: all 2s ease;
}

.Main_Avatar:hover {
    transform: rotate(5deg);
    background-color: var(--color-primary);
    border: 3px solid #FFF;
    border-left: 1px;
    border-top: 0;
}
.Main_Info_basic{
    display: grid;
    place-items: center;
}
.Main_Name {
    margin-top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4em;
    color: #FFF;
    text-align: center;
    margin-top: -10px;
    width: 100%;
    border-right: 0.15em solid #333;
    white-space: nowrap;
    overflow: hidden;
    animation: typing 3s steps(22), blink .5s step-end infinite;
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
}  

.Main_Rol {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    color: var(--color-primary);
    white-space: nowrap;
    overflow: hidden;
    animation: typing 6s steps(22), blink .5s step-end infinite;
}

.Main_Dates {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 20px;
}

.Main_Dates li a {
    border-bottom: 1px solid #ffffff33;
    border-left: 1px solid #ffffff33;
    border-right: 1px solid #ffffff33;
    border-radius: 2px;
    padding: 6px 6px 6px 6px;
    width: 100px;
    margin: 0 10px 0px 10px;
    text-align: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    align-items: center;
    color: #FFF;
    cursor: pointer;
    text-decoration: none;
    transition: all 1s ease;
}

.Main_Dates li a:hover  {
    background-color: var(--color-primary);
    color: var(--color-secondary);
}


.Main_Dates li:hover .Main_Icons {
    color: var(--color-secondary);
}

.Main_List li a  {
    color: #FFF;
    padding: 8px 4px 8px 8px;
    transition: all 0.5s ease;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
}
.Main_List li  {
    transition: all 0.5s ease;
    
}
.Main_List li:hover {
    background-color: #FFF;
    color: #040913;
    cursor: pointer;
    padding-left: 20px;
    border-left: 10px solid var(--color-primary);
}
.Main_List li:hover a {
    color: #040913;
    cursor: pointer;
 
}

.Main_Icons {
    color: var(--color-primary);
}
.Main_Progress_bar{
    width: 2px;
    height: 100vh;
    right:0;
    background-color: var(--color-primary);
    border-radius: 20px;
    position: absolute;
    padding:0;
    box-shadow: 	
    0 0 1px  rgba(39, 214, 16, .3),
    0 0 2px  rgba(39, 214, 16, .3),
    0 0 4px rgba(39, 214, 16,  .3),
    0 0 8px rgba(66, 201, 144, .3),
    0 0 16px rgba(66, 201, 144,.3),
    0 0 32px rgba(66, 201, 144,.3),
    0 0 64px rgba(38, 172, 105,.3),
    0 0 68px rgba(38, 172, 105,.3),
    0 0 70px rgba(38, 172, 105,.3);
}
.Main_Progress_bar:after{
    content: '';
    display: block;
    background: linear-gradient(to right, #FFF 80%, #20CE65 100%);
    width: 100%;
    height: var(--progres-scroll-y);
    border-radius: 9px;
}
.Main_Social{
    position: fixed;
    bottom: 10px;
    width: 25vw;
}
.Main_Social ul {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top: 5px;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 0;
    color: var(--color-primary);
}
.Main_Social ul li a {
    color: var(--color-primary);
    font-size: 1em;
    transition: all 0.5s ease;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.Main_Social ul li a:hover {
 color: #FFF;
}

/*regla visualizacion moviles breack point*/
@media (max-width: 660px ) {
    .Main {
        width: 100%;
        position: relative;
        height: auto;
        padding-bottom: 100px;
        padding-bottom: 10px;
    }
    .Main_Progress_bar{
        display: none;
    }
    .Main_Social {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: var(--color-secondary);
        padding: 10px;
        z-index: 9;
    }
    .Main_Social ul {
        padding: 0;
    }
    .Main_Social ul li a{
       font-size: 11px;
    }
}

@media only screen and (min-width:660px) and (max-width: 1000px) {
    .Main {
        width: 100%;
        position: fixed;
        z-index: 3;
    }
    .Main_Info_basic {
        grid-area: Main_Info_basic;
        display: grid;
        grid-template-columns: 1fr 6fr;
        grid-gap: 2px;
        grid-template-areas:
            "Main_Avatar Name"
            "Main_Avatar Rol";
        align-content: center;
        justify-content: center;
        min-width: 400px;
        min-height: 80px;
        ;
    }

    .Main_Avatar {
        width: 100%;
        margin: 5px 5px 5px 5px;
        grid-area: Main_Avatar;
        background-color: #20CE65;
        text-align: center;
    }

    .Main_Name {
        font-size: 1em;
        grid-area: Name;
        padding: 0;
        margin: 0;
        align-self: flex-end;
        text-align: left;
        margin-left: 10px;
    }

    .Main_Rol {
        padding: 0;
        margin: 0;
        align-self: flex-start;
        font-size: 0.8em;
        padding-bottom: 10px;
        grid-area: Rol;
        text-align: left;
        margin-left: 10px;
    }

    .Main_List {
        grid-area: Menu_List;
    }

    .Main_Dates {
        grid-area: Main_Dates;
        height: 2em;
        font-size: .8em;
        display: flex;
        justify-content: flex-end;
    }

    .Main {
        width: 100%;
        display: flex;
        height: auto;
        display: grid;
        grid-template-areas:
            "Main_Info_basic Main_Dates"
            "Menu_List Menu_List";
        padding: 0 10px 0 0;
    }

    .Main_List {
        width: 98.5%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: -40px;
    }

    .Main_List li {
        font-size: .7em;
        min-width: auto;
        padding: 
        .5px 4px 5px 10px;
    }
    .Main_Progress_bar{
        display: none;
    }

    
/*regla visualizacion moviles breack point*/
.Main_Social {
    position: fixed;
    right: 0;
    top: calc(50% - 120px);
    width: 30px;
    height: 120px;
    margin: 0;
    padding: 0;
    background-color: var(--color-secondary);
    padding: 10px;
}
.Main_Social ul {
    display: block;
    font-family: 'Montserrat', sans-serif;
    color: var(--color-primary);
}
.Main_Social ul li a:hover{
    color: #FFF;
}
.Main_Social ul li a{
    display: block;
}
.Main_Social a span {
    visibility: hidden;
}

}